import { gsap as __gsap } from 'gsap'

export type TUseGSAP = {
  // @ts-ignore
  context: __gsap.Context
  contextSafe: Function
}

export const useGSAP = (callback: Function): TUseGSAP => {
  const context = __gsap.context(() => {})

  const contextSafe = (cb: Function = callback) => {
    context.add(cb)
  }

  onMounted(() => {
    callback && context.add(callback)
  })

  onUnmounted(() => {
    context.revert()
  })

  return { context, contextSafe }
}
